.why-us {
    padding-inline: 5em;
}

.why-us__heading {
    font-size: 2rem;
}

.why-us__text {
    color: var(--grayish-blue);
    max-width: 60ch;
    line-height: 1.6;
}

.why-us__main {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    margin-top: 3em;
    gap: 3em;
}

@media(max-width:1100px) {
    .why-us__main {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .why-us__header {
        width: fit-content;
        margin: 0 auto;
        text-align: center;
        margin-bottom: 4em;
    }
}

@media(max-width:650px) {
    .why-us {
        margin-top: 8em;
    }
}

@media(max-width:450px) {
    .why-us__heading {
        font-size: 1.5rem;
    }

    .why-us__text {
        font-size: .8rem;
    }
}