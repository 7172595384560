*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: var(--font);
}

:root {
  --font: 'Public Sans', sans-serif;
  font-size: 18px;

  /* colors */
  --dark-blue: hsl(233, 26%, 24%);
  --lime-green: hsl(136, 65%, 51%);
  --bright-cyan: hsl(192, 70%, 51%);
  --gradient: linear-gradient(90deg, var(--lime-green), var(--bright-cyan) 100%);


  --grayish-blue: hsl(233, 8%, 62%);
  --light-grayish-blue: hsl(220, 16%, 96%);
  --very-light-gray: hsl(0, 0%, 98%);
  --white: hsl(0, 0%, 100%);
}

a {
  text-decoration: none;
  color: inherit;
}

p {
  margin: 0;
}