.latest {
    margin-top: 10em;
    padding-inline: 5em;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.latest__heading {
    font-size: 2rem;
    margin-bottom: 2.5em;
}

.latest__container {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 3em;
}

@media(max-width:1500px) {
    .latest {
        align-items: center;
    }

    .latest__container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        margin: 0 auto;
        column-gap: 10%;
        row-gap: 5em;
        padding-inline: 3em;
        text-align: center;
    }
}

@media(max-width:900px) {

    .latest__heading {
        text-align: center;
    }

    .latest__container {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0;
    }
}

@media(max-width:500px) {
    .latest {
        padding-inline: 1em;
    }
}