.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2em 7em;
    background-color: white;
    position: fixed;
    width: 100vw;
    z-index: 99;
    box-shadow: 0 5px 5px rgba(0, 0, 0, .2);
}

.header__nav {
    display: flex;
    align-items: center;
    gap: 2em;
}

.header__nav-link {
    color: var(--grayish-blue);
    position: relative;
    transition: all 150ms ease;
}

.header__nav-link:hover {
    color: black;
}

.header__nav-link:hover::after {
    content: '';
    width: 100%;
    height: 5px;
    background: var(--gradient);
    position: absolute;
    bottom: -25px;
    left: 0;
}

.header__toggle {
    display: none;
}

@media(max-width: 1100px) {
    .header__toggle {
        display: block;
    }

    .header__nav {
        display: hidden;
        transform: scaleY(0) translateX(-50%);
        transform-origin: top;
        position: absolute;
        width: 90%;
        max-width: 500px;
        top: 120px;
        left: 50%;
        background-color: white;
        flex-direction: column;
        padding: 1.5em;
        box-shadow: 5px 5px 5px rgba(0, 0, 0, .2);
        border-radius: 10px;
        transition: transform 150ms ease;
    }

    .header__nav--active {
        display: flex;
        transform: scaleY(1) translateX(-50%);
    }

    .header__nav-link {
        color: black;
        padding: .5em 8em;
    }

    .header__button {
        display: none;
    }
}

@media(max-width:650px) {
    .header {
        padding: 2em;
        position: fixed;
        width: 100vw;
    }

    .header__nav-link {
        padding: .5em 2em;
    }
}